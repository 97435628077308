import { Auth } from 'aws-amplify'
// import core components
import { usersService } from 'services';
import _ from 'lodash';
// helper methods to consume Cognito services
const signIn = async (email, password, bFirst = false) => {
    await Auth.signIn(email, password);
    return await checkUser(bFirst, email);
}
const signUp = async data => {
    await Auth.signUp(data);
}
const signOut = () => {
  const localItems = { ...localStorage };
  _.forEach(localItems, (val, key) => {
    if (key.startsWith('CognitoIdentityServiceProvider')) {
      localStorage.removeItem(key);
    }
  });
 return Promise.resolve();
  // return Auth.signOut();
};
const changePassword = async (oldPWd, newPWD) => {
    const user = await Auth.currentAuthenticatedUser()
    return await Auth.changePassword(user, oldPWd, newPWD);
}
const checkUser = async (bFirst, email = "") => {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    let user = await usersService.getUser(cognitoUser.username);
    if(user === null) {
        return await usersService.addUser({cognitoUserId: cognitoUser.username, emailAddress: email});
    } else 
        return user[0];
}
const confirmSignUp = async (email, authCode) => {
    await Auth.confirmSignUp(email, authCode);
}
/*
  Login in new tab and wait for its process to be completed
*/ 
const federatedSignIn = async provider => {
  const loginTab = window.open(
    `${window.location.origin}/oauth/?provider=${provider}`,
    '',
    `left=${(window.innerWidth - 500) / 2},width=500,height=500`
  );
  const checkIsLoggedIn = async (resolve, maxWait = 120) => {
    const data = await Auth.currentUserInfo();
    if (!data && maxWait != 0 && !loginTab.closed) {
      setTimeout(() => {
        maxWait -= 0.5;
        checkIsLoggedIn(resolve, maxWait);
      }, 500);
    } else {
      resolve(data);
    }
  };

  const promise = new Promise(resolve => {
    checkIsLoggedIn(resolve);
  });

  const data = await promise;

  return {
    data,
    tab: loginTab,
    isBrowserClosed: loginTab.closed,
  };
};

const sendCode = async userName => {
    await Auth.forgotPassword(userName);
}
const resetPassword = async (userName, code, newPwd) => {
    return await Auth.forgotPasswordSubmit(userName, code, newPwd);
}
const resendSignupCode = async userName => {
    return await Auth.resendSignUp(userName);
}
export const cognitoService = {
    signIn,
    signUp,
    signOut,
    changePassword,
    checkUser,
    confirmSignUp,
    federatedSignIn,

    sendCode,
    resetPassword,
    resendSignupCode
};