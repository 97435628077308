const moment = require('moment-timezone');
const moment_days = require('moment-business-days');
const TZ = "Asia/Singapore";
const LONG_DATE_FORMAT = "EEEE, MMMM d, yyyy";
const SHORT_DATE_FORMAT = "YYYY-MM-DD";
const STRING_FORMAT = "dddd, MMMM Do, YYYY";
const SHORT_STRING_FORMAT = "MMMM Do, YYYY";
const DISPLAY_DATE_FORMAT = "MMMM Do, YYYY";
const STRING_DATE_FORMAT = " D MMM, YYYY"
export const updateLocale = (code, l) => {
    moment_days.updateLocale(code, l);
}
export const getCurrentHour = () => {
    return moment().tz(TZ).format('HH');
}
export const getShortToday = () => {
    return moment().tz(TZ).format(SHORT_DATE_FORMAT);
}
export const getNextDay = date => {
    var nextDay = moment(date, LONG_DATE_FORMAT).add(1, 'days');    
    return nextDay;
}
export const getNextBusinessDay = date => {
    const nextDay = getNextDay(date);    
    return moment_days(nextDay).isBusinessDay() ? nextDay : moment_days(nextDay).nextBusinessDay()._d;
}
export const isBusinessDay = date => {
    return moment_days(date).isBusinessDay();
}
export const getLongDateFormat = () => {
    return LONG_DATE_FORMAT;
}
export const getShortDateFormat = () => {
    return SHORT_DATE_FORMAT;
}
export const toDateStr = (date, bShort = false) => {
    return moment(date).format(bShort?SHORT_STRING_FORMAT:STRING_FORMAT);
}
export const isSameDay = date => {
    return moment(date).diff(moment(new Date()), 'days') === 0;
}
export const toShortDate = date => {
    return moment(date).format(SHORT_DATE_FORMAT);
}
export const toDisplayDate = date => {
    return moment(date).format(DISPLAY_DATE_FORMAT);
}
export const checkDateInRange = (dateToCompare, startDate, endDate) => {
    return dateToCompare >= startDate && dateToCompare <= endDate;
}
export const compareTwoDates = (date1, date2) => {
    return toShortDate(date1) === toShortDate(date2);
}
export const toUTCDateTime = date => {
    return moment(date).format("DD/MM/YYYY h:m A");
}
export const toMilliseoncds = m => {
    return m * 60 * 1000;
}
export const toMomentDate = date => {
    return moment(date).format(STRING_DATE_FORMAT);
}
export const getSingPostDay = (date) => {
    return moment(date).clone().add(4, 'days').nextBusinessDay();
  }