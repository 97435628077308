const removeCanvas = key => {
    localStorage.removeItem(key);
}
const loadCanvas = key => {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
}
const saveCanvas = (key, {outlay, inlay_0, inlay_1}) => {
    try {
        localStorage.setItem(key, JSON.stringify({
            outlay,
            inlay_0,
            inlay_1,
        })
    );
    } catch (error) {
        ////console.log('too large size');
    }
}
const getUser = () => {
    const user = localStorage.getItem('user');
    return user ? JSON.parse(user) : null;
}
const saveUser = user => {
    localStorage.setItem('user', JSON.stringify(user));
}
const removeUser = () => {
    localStorage.removeItem('user');
}
const saveDesignId = id => {
    localStorage.setItem('design-id', id);
}
const getDesignId = () => {
    return localStorage.getItem('design-id');
}
const clearDesignId = () => {
    localStorage.removeItem('design-id');
}
export const localstorageHelper = {
    getUser, // get user
    saveUser, // save user
    removeUser, // remove user

    loadCanvas, // load canvas objects
    saveCanvas,     // save canvas objects
    
    removeCanvas,  // clear front-step canvas objects

    saveDesignId, // Save the current design id
    getDesignId, // Get the current design id
    clearDesignId,
};