import S3 from 'react-aws-s3';
import * as fflate from 'fflate';

// import core components
import { canvasHelper } from 'helpers';

if(typeof window != "undefined"){
  window.Buffer = window.Buffer || require("buffer").Buffer;
}

//export const Buffer = () => typeof window !== "undefined"

export const s3Service = {
  uploadTextArray,
  readTextFile,
};

async function uploadFile(dataStr, bucketName, dirName, fileName) {
  const config = {
    bucketName,
    dirName,
    region: process.env.GATSBY_S3_REGION,
    accessKeyId: process.env.GATSBY_S3_ACCESS_ID,
    secretAccessKey: process.env.GATSBY_S3_ACCESS_KEY,
  };
  const ReactS3Client = new S3(config);
  const s3Item = await ReactS3Client.uploadFile(dataStr, fileName);
  return s3Item.key;
}

async function uploadTextArray(textArr, fileName) {
  const s3Keys = [];
  let promises = [];
  for await (let [i, text] of textArr.entries()) {
    const buf = fflate.strToU8(text);
    const gzipped = await fflate.gzipSync(buf, { level: 9 });
    promises.push(
      uploadFile(
        gzipped,
        process.env.GATSBY_S3_DESIGN_BUCKET,
        'user-uploads',
        `${fileName}_0${i + 1}.txt`
      )
    );
  }
  await Promise.all(promises).then(values => {
    values.map(item => s3Keys.push(item));
  });
  return s3Keys;
}

async function readTextFile(bucket, key) {
  const config = {
    bucketName: bucket,
    region: process.env.GATSBY_S3_REGION,
    accessKeyId: process.env.GATSBY_S3_ACCESS_ID,
    secretAccessKey: process.env.GATSBY_S3_ACCESS_KEY,
  };
  const ReactS3Client = new S3(config);
  const data = await ReactS3Client.getObject({ Key: key });
}
