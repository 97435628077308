import { handleResponse } from './common';

export const offersService = {
    getOffers,
};

async function getOffers(id) {
    const requestOptions = {
        method: "GET",
        headers: {'Content-Type': 'application/json'},
    };
    return handleResponse(`${process.env.GATSBY_API_URL}/offer/${id}`, requestOptions);
}

