import { handleResponse } from './common';

export const designsService = {
    getDesign,
    getDesigns,
    saveDesign,
    updateDesign,
    removeDesign,
    saveDesignAsync,
    updateDesignAsync,
};

function saveDesign(design) {
    const requestOptions = {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(design)
    };
    return handleResponse(`${process.env.GATSBY_API_URL}/savedcards`, requestOptions);
}
function saveDesignAsync(design) {
    const requestOptions = {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(design)
    };
    return fetch(`${process.env.GATSBY_API_URL}/savedcards`, requestOptions);
}
function updateDesignAsync(id, design) {
    const requestOptions = {
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(design)
    };
    return fetch(`${process.env.GATSBY_API_URL}/savedcards/${id}/`, requestOptions);
}

async function getDesigns(userId) {
    const requestOptions = {
        method: "GET",
        headers: {'Content-Type': 'application/json'},
    };
    return handleResponse(`${process.env.GATSBY_API_URL}/savedcards/list/${userId}`, requestOptions);
}

async function getDesign(designId) {
    const requestOptions = {
        method: "GET",
        headers: {'Content-Type': 'application/json'},
    };
    return handleResponse(`${process.env.GATSBY_API_URL}/savedcards/${designId}`, requestOptions);
}

async function removeDesign(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {'Content-Type': 'application/json'},
    };
    return handleResponse(`${process.env.GATSBY_API_URL}/savedcards/${id}/`, requestOptions);
}

async function updateDesign(id, design) {
    const requestOptions = {
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(design)
    };
    return handleResponse(`${process.env.GATSBY_API_URL}/savedcards/${id}/`, requestOptions);
}