import _ from 'lodash';
import { compareTwoDates } from 'utils/moment';
import { lineItemsService } from 'services';
import { convertToFloat } from '../utils/math';
import { window } from 'browser-monads';

function onCartReady() {
    // Event handlers for Snipcart
    Snipcart.events.on('item.removed', (cartItem) => {
        cartItemsService.handleRemoveCartItem(cartItem);
    });
}
async function addItemToCart(itemData) {
    Snipcart.api.cart.items.add({
        id: itemData.itemId,
        name: itemData.itemName,
        price: itemData.itemPrice,
        url: itemData.itemUrl,
        metadata: itemData.metadata,
    })
};
async function updateItemToCart(id, itemData) {
    Snipcart.api.cart.items.update({
        uniqueId: id,
        id: itemData.itemId,
        name: itemData.itemName,
        price: itemData.itemPrice,
        url: itemData.itemUrl,
        metadata: itemData.metadata,
    });
};
async function updateCartItemField(cartItem) {
    Snipcart.api.cart.items.update({
        uniqueId: cartItem.uniqueId,
        id: cartItem.id,
        name: cartItem.name,
        price: cartItem.price,
        url: cartItem.url,
        metadata: cartItem.metadata,
    });
};
function clearCart() {
    Snipcart.api.items.clear().then(function (removedItems) {
        ////console.log(removedItems);
    });
}
function openCart() {
    Snipcart.api.theme.cart.open();
}
function getCartItems() {
    return window.Snipcart.store.getState().cart.items.items;
}
function getCartItem(uniqueId) {
    return Snipcart.store.getState().cart.items.items.find(item => item.uniqueId === uniqueId);
}
function getSameDeliveryItems(addr, dTime, courior) {
    // addr is delivery address
    // dTime is dispatch time
    const cartItems = getCartItems();
    return cartItems.filter(cartItem =>compareTwoDates(cartItem.metadata.delivery.dispatchDate, dTime) && 
            compareDeliveryAddresses(addr, cartItem.metadata.user) && 
            cartItem.metadata.delivery.option === courior);
}
function compareWithCartItem(addr, dTime, courior, cartItem) {
    return compareTwoDates(cartItem.metadata.delivery.dispatchDate, dTime) && 
        compareDeliveryAddresses(addr, cartItem.metadata.user) && 
        cartItem.metadata.delivery.option === courior;
}
function compareDeliveryAddresses(addr1, addr2) {
    return addr1.address1.toUpperCase() === addr2.address1.toUpperCase() &&
        addr1.address2.toUpperCase() === addr2.address2.toUpperCase() &&
        addr1.country.toUpperCase() === addr2.country.toUpperCase() &&
        addr1.locality.toUpperCase() === addr2.locality.toUpperCase() &&
        addr1.postcode === addr2.postcode &&
        (addr1.state || "").toUpperCase() === (addr2.state || "").toUpperCase();
}
async function handleRemoveCartItem(cartItem) {
    try {
        // remove lineItem from db
        lineItemsService.removeLineItem(cartItem.metadata.lineItemId);

        if(cartItem.metadata.delivery.price !== "$0.00") {
            // Only if the removed item had a delivery price
            const matchedItems = getSameDeliveryItems(cartItem.metadata.user, cartItem.metadata.delivery.dispatchDate, cartItem.metadata.delivery.option);
            if(matchedItems.length > 0) {
                // If there are added items that deducted delivery price
                await lineItemsService.updateLineItemDeliveryPrice(matchedItems[0].metadata.lineItemId, cartItem.metadata.delivery.price);
                const newItemData = {...matchedItems[0]};
                newItemData.metadata.delivery.price = cartItem.metadata.delivery.price;
                newItemData.metadata.price.total = convertToFloat(convertToFloat(newItemData.price) + convertToFloat(newItemData.metadata.delivery.price));
                newItemData.price = newItemData.metadata.price.total;
                await updateCartItemField(newItemData);
            }
        }
    } catch (error) {
        ////console.log(error);
    }
}
async function handleUpdateCartItem(cartItem) {
    try {
        const matchedItems = getSameDeliveryItems(cartItem.metadata.user, cartItem.metadata.delivery.dispatchDate, cartItem.metadata.delivery.option);
        if(matchedItems.length > 0) {
            // If there are added items that deducted delivery price
            await lineItemsService.updateLineItemDeliveryPrice(matchedItems[0].metadata.lineItemId, cartItem.metadata.delivery.price);
            const newItemData = {...matchedItems[0]};
            newItemData.metadata.delivery.price = cartItem.metadata.delivery.price;
            newItemData.metadata.price.total = convertToFloat(convertToFloat(newItemData.price) + convertToFloat(newItemData.metadata.delivery.price));
            newItemData.price = newItemData.metadata.price.total;
            await updateCartItemField(newItemData);
        }
    } catch (error) {
        ////console.log(error);
    }
}
export const cartItemsService = {
    getCartItems,
    getCartItem,
    addItemToCart,
    updateItemToCart,
    clearCart,
    openCart,
    getSameDeliveryItems,
    compareDeliveryAddresses,
    handleRemoveCartItem,
    handleUpdateCartItem,
    onCartReady,
    compareWithCartItem,
};