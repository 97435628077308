import { handleResponse } from './common';
import { convertToFloat } from 'utils/math';

export const lineItemsService = {
    addLineItem,
    getLineItem,
    removeLineItem,
    updateLineItemDeliveryPrice,
};

async function addLineItem(lineItem) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(lineItem)
    };
    return handleResponse(`${process.env.GATSBY_API_URL}/lineitem`, requestOptions)
}
async function updateLineItem(lineItem) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(lineItem)
    };
    return handleResponse(`${process.env.GATSBY_API_URL}/lineitem`, requestOptions)
}
async function getLineItem(id) {
    const requestOptions = {
        method: 'GET',
        headers: {'Content-Type': 'application/json'},
    };
    return handleResponse(`${process.env.GATSBY_API_URL}/lineitem/${id}`, requestOptions)
}
async function removeLineItem(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {'Content-Type': 'application/json'},
    };
    return handleResponse(`${process.env.GATSBY_API_URL}/lineitem/${id}`, requestOptions)
}
async function updateLineItemDeliveryPrice(id, price) {
    const lineItem = await getLineItem(id);
    
    const requestOptions = {
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
            "priceSubtotal": convertToFloat(lineItem.priceTotal + convertToFloat(price)),
            "priceTotal": convertToFloat(lineItem.priceTotal + convertToFloat(price)),
        })
    };
    return handleResponse(`${process.env.GATSBY_API_URL}/lineitem/${id}`, requestOptions)
}