import { parseEmail } from 'utils/regex';
import { canvasHelper } from './canvasHelper';

function isCourierOptionSelected(deliveryOption) {
    return deliveryOption && (deliveryOption.name === "Same Day Delivery" || deliveryOption.name === "Next Day Delivery");
}
function validateData(deliveryOption, deliveryUser, deliveryAddress) {
    let val = [];
    //deliveryOption || val.push("Delivery option is missing");
    deliveryUser.fullName || val.push("Full name is missing");
    (!isCourierOptionSelected(deliveryOption) ||
                  isCourierOptionSelected(deliveryOption) && deliveryUser.phone)
            || val.push("Phone number is missing");
    deliveryAddress.address1 || val.push("Address is missing");
    deliveryAddress.locality || val.push("City is missing");
    deliveryAddress.postcode || val.push("Postal code is missing");
    deliveryAddress.country || val.push("Country is missing");
    (deliveryUser.email && parseEmail(deliveryUser.email)) || val.push("Email is invalid");
    return val;
}

function getLayoutBasedOnJsonDesign(newDesign) {
    const {inlay0,inlay1}=newDesign;
    const isCanvasInlay0 = canvasHelper.isCanvasJson(inlay0.json)
    const isCanvasInlay1 = canvasHelper.isCanvasJson(inlay1.json);
    const inlay0Array=[];
    const inlay1Array=[];
    if(isCanvasInlay0){
        for (let index = 0; index < inlay0.json.objects.length; index++) {
            inlay0Array.push('PHOTO');
        }
    } else {
        for (let index = 0; index < inlay0.json.content.length; index++) {
            inlay0Array.push('TEXT');
        }
    }
    if(isCanvasInlay1){
        for (let index = 0; index < inlay1.json.objects.length; index++) {
            inlay1Array.push('PHOTO');
        }
    } else {
        for (let index = 0; index < inlay1.json.content.length; index++) {
            inlay1Array.push('TEXT');
        }
    }
    return [inlay0Array,inlay1Array]


}
export const multistepHelper = {
    isCourierOptionSelected,
    validateData,
    getLayoutBasedOnJsonDesign,
};
