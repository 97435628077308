/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
// You can delete this file if you're not using it
import { Amplify, Auth } from 'aws-amplify';
import awsConfig from "./src/aws-exports"
awsConfig.oauth.domain = process.env.GATSBY_COGNITO_OAUTH_DOMAIN;
// import core components
import { cartItemsService } from 'services';    
import UserContextProvider from "./src/contexts/UserContextProvider";

Amplify.configure(awsConfig)

document.addEventListener("snipcart.ready", cartItemsService.onCartReady);

export const wrapRootElement = ({ element }) => (
    <UserContextProvider>{element}</UserContextProvider>
  )

