import { number } from 'prop-types';

export const getTotal = (cardP, q, deliveryP) => {
  return convertToFloat(getMultiple(cardP, q) + convertToFloat(deliveryP));
};
export const getSubtotal = (cardP, q) => {
  return convertToFloat(getMultiple(cardP, q));
};
export const getMultiple = (cardP, q) => {
  const parsedPrice = typeof cardP === 'string' ? convertToFloat(cardP) : cardP;
  return convertToFloat(parsedPrice * q);
};
export const convertToFloat = str => {
  switch (typeof str) {
    case 'number':
      return parseFloat(str.toFixed(2));
    case 'string':
      return parseFloat(parseFloat(str.replace(/[^0-9.]/g, '')).toFixed(2));
    default:
      return null;
  }
};
export const getSubsum = (cardP, v) => {
  return convertToFloat(cardP) - convertToFloat(v);
};
export const getPercent = (cardP, v) => {
  return convertToFloat(convertToFloat(cardP) * (1 - convertToFloat(v) / 100));
};
