function generateUUID() {
    var d = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (d + Math.random()*16)%16 | 0;
        d = Math.floor(d/16);
        return (c=='x' ? r : (r&0x3|0x8)).toString(16);
    });
    return uuid;
};
// generate slug from the name by removing non-alphanumeric letters and replace " " with "-"
function generateSlug(name) {
    return name ? name.toLowerCase().replace(/\s+/g, '-').replace(/[^=+?/a-zA-Z0-9-_]/g, '') : "/404";
}
// genate slug for cards
function generateCardSlug(category, title) {
    return generateSlug(`/greeting-cards/${category}/${title}`);
}
// genate slug for categories
function generateCategorySlug(category) {
    return generateSlug(`/greeting-cards/${category}`);
}
function generateUniqueId(str) {
    return `${generateSlug(str)}_${generateUUID()}`;
}
const slugHelper = {
    generateSlug,
    generateCardSlug,
    generateCategorySlug,
    generateUniqueId,
}
module.exports = slugHelper
