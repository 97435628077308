import { handleResponse } from './common';

export const usersService = {
    addUser,
    getUser,
    updateUser,
};

async function addUser(user) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(user)
    };
    return handleResponse(`${process.env.GATSBY_API_URL}/useraccount`, requestOptions);
}

async function getUser(id) {
    const requestOptions = {
        method: 'GET',
        headers: {'Content-Type': 'application/json'},
    };
    return handleResponse(`${process.env.GATSBY_API_URL}/useraccount/${id}`, requestOptions);
}

async function updateUser(user) {
    const requestOptions = {
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(user)
    };
    return handleResponse(`${process.env.GATSBY_API_URL}/useraccount/${user.cognitoUserId}/`, requestOptions);
}