import { handleResponse } from './common';
import { usersService } from './usersService';

export const addressService = {
    addAddress,
    getAddresses,
    getDeliveryAddresses,
    removeAddress,
    updateAddress,
    setBillingAddressOfUser,
};

function mapToDBFields(addr) {
    return JSON.stringify({
        userId : addr.userId,
        friendlyName : addr.fullName,
        relationshipName : addr.relationship,
        addressName : addr.fullName,
        addressCompany : addr.company,
        addressLine1 : addr.address1,
        addressLine2 : addr.address2,
        addressCity : addr.locality,
        addressStateProvince : addr.state,
        addressPostcode : addr.postcode,
        addressPhoneNumber : addr.phone,
        addressCountry: addr.country,
        addressType:addr.addressType,
        addressDefault:addr.addressDefault,
    });
}
async function addAddress(addr) {
    const requestOptions = {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: mapToDBFields(addr)
    };
    //console.log(mapToDBFields(addr), "added address");
    return handleResponse(`${process.env.GATSBY_API_URL}/addressbook`, requestOptions);
}

async function getAddresses(userId) {
    const requestOptions = {
        method: 'GET',
        headers: {'Content-Type': 'application/json'},
    };
    return handleResponse(`${process.env.GATSBY_API_URL}/addressbooklist/${userId}`, requestOptions);
}

// Filter out billing address types when loading the deivery addressbook during the workflow
async function getDeliveryAddresses(userId) {
    const addresses = await getAddresses(userId);
    if(!addresses || addresses.length===0){
        return;
    }
    var address = addresses.filter(function (el) {
        return el.addressType != 'billing';
    })
    // added sort to return addresses in alphabetical order by name
    return address = [...address].sort((a, b) => a.addressName > b.addressName ? 1 : -1);
}

async function removeAddress(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {'Content-Type': 'application/json'},
    };
    return handleResponse(`${process.env.GATSBY_API_URL}/addressbook/${id}/`, requestOptions);
}

async function updateAddress(id, addr) {
    const requestOptions = {
        method: "PUT",
        headers: {'Content-Type': 'application/json'},
        body: mapToDBFields(addr)
    };
    return handleResponse(`${process.env.GATSBY_API_URL}/addressbook/${id}/`, requestOptions);
}

async function setBillingAddressOfUser(userId) {
    const addresses = await getAddresses(userId);
    if(!addresses || addresses.length===0){
        return;
    }
    const address = addresses.find(address=>address.addressType==="billing" && address.addressDefault === true);
    const users = await usersService.getUser(userId);
    if(!users) {
        return;
    }
    if(address){
        return Snipcart.api.cart.update({
            email: users[0].emailAddress,
            billingAddress:{
                name: address.addressName,
                company: address.addressCompany,
                address1: address.addressLine1,
                address2: address.addressLine2,
                city: address.addressCity,
                country: address.addressCountry,
                province: address.addressStateProvince,
                postalCode: address.addressPostcode,
                phone: address.addressPhoneNumber,
            },
            shipToBillingAddress: true,
        })
    }
}
