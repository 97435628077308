import { handleResponse } from './common';

export const ordersService = {
    getOrder,
    getOrders,
    removeOrder
};

async function getOrders(userId) {
    const requestOptions = {
        method: "GET",
        headers: {'Content-Type': 'application/json'},
    };
    return handleResponse(`${process.env.GATSBY_API_URL}/orders-db/${userId}`, requestOptions);
}

async function getOrder(orderId) {
    const requestOptions = {
        method: "GET",
        headers: {'Content-Type': 'application/json'},
    };
    return handleResponse(`${process.env.GATSBY_API_URL}/orders/orderid/${orderId}`, requestOptions);
}

async function removeOrder(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {'Content-Type': 'application/json'},
    };
    return handleResponse(`${process.env.GATSBY_API_URL}/orders/orderid/${id}/`, requestOptions);
}