export const canvasHelper = {
  getBackgroundImgUrl,
  getOverlayImgUrl,
  getCanvasSize,
  getObjectImgUrl,
  getObjectFitInImgUrl,
  getJson,
  getDataURL,
  isCanvasJson,
};

const DEFAULT_CANVAS_WIDTH = 500;
const DEFAULT_CANVAS_HEIGHT = 661;

const DEFAULT_OBJECT_WIDTH = 100;
const DEFAULT_OBJECT_HEIGHT = 100;

function getCanvasSize(obj) {
  const canvasWidth = obj && obj.width ? obj.width : DEFAULT_CANVAS_WIDTH;
  const canvasHeight = obj && obj.height ? obj.height : DEFAULT_CANVAS_HEIGHT;
  return { canvasWidth, canvasHeight };
}
function getBackgroundImgUrl(obj) {
  try {
    let uri =
      typeof obj.backgroundImage == 'string'
        ? obj.backgroundImage
        : obj.backgroundImage.src;
    const { canvasWidth, canvasHeight } = getCanvasSize(obj);
    return `${process.env.GATSBY_S3_BUCKET_BASE_URL}/fit-in/${canvasWidth *
      2}x${canvasHeight * 2}/backgrounds/${uri}`;
  } catch (error) {
    return '';
  }
}
function getOverlayImgUrl(obj) {
  try {
    let uri =
      typeof obj.overlayImage == 'string'
        ? obj.overlayImage
        : obj.overlayImage.src;
    const { canvasWidth, canvasHeight } = getCanvasSize(obj);
    return `${process.env.GATSBY_S3_BUCKET_BASE_URL}/fit-in/${canvasWidth *
      2}x${canvasHeight * 2}/overlays/${uri}`;
  } catch (error) {
    return '';
  }
}
function getObjectImgUrl(type, name) {
  return `${process.env.GATSBY_S3_BUCKET_BASE_URL}/${type}/${name}`;
}
function getObjectFitInImgUrl(
  type,
  name,
  width = DEFAULT_OBJECT_WIDTH,
  height = DEFAULT_OBJECT_HEIGHT
) {
  return `${process.env.GATSBY_S3_BUCKET_BASE_URL}/fit-in/${width}x${height}/${type}/${name}`;
}
function getJson(ref) {
  if (ref.current) {
    return JSON.stringify(ref.current.toJson());
  }
  throw new Error('invalid canvas');
}
function getDataURL(ref) {
  if (ref.current) return ref.current.toDataURL();
  throw new Error('invalid canvas');
}

function isCanvasJson(json) {
  return json && json.version;
}
