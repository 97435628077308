// generate slug from the name by removing non-alphanumeric letters and replace " " with "-"
import domtoimage from 'dom-to-image';

function getDomById(id) {
    return document.getElementById(id);
}
function triggerClick(id) {
    getDomById(id).click();
}
function changeProperty(id, type, value) {
    getDomById(id)[type] = value;
}
function getLocation() {
    return typeof window !== 'undefined' ? window.location.href : '';
}
async function getImageFromDom(id) {
    var node = getDomById(id);
    var imgData = null;
    try {
        imgData = await domtoimage.toPng(node);
    } catch (error) {
        //console.error('oops, image converting went wrong!', error);
    }
    return imgData;
}

function scrollHandler() {
    // Get the current page scroll position

        // if any scroll is attempted,
        // set this to the previous value
        window.onscroll = function() {
            let scrollTop = 
            window.pageYOffset || document.documentElement.scrollTop;
            const scrollLeft = 
            window.pageXOffset || document.documentElement.scrollLeft;
            let extraHeightToRemove = document.documentElement.clientHeight;
            if(document.documentElement.clientHeight!==window.visualViewport.height) {
                extraHeightToRemove=document.documentElement.clientHeight-window.visualViewport.height;
            }
            const scrollMaxY = (document.documentElement.scrollHeight - (extraHeightToRemove))
            if(scrollTop>scrollMaxY && scrollMaxY>0) {
                scrollTop= scrollMaxY;
                window.scrollTo(scrollLeft, scrollMaxY);
            }
        };
}

function enableScroll() {
    window.onscroll = function() {
        ////console.log('enabled',document.documentElement.scrollHeight,document.documentElement.clientHeight,document.documentElement.scrollTop)
    };
}
export const domHelper = {
    getLocation,
    getDomById,
    getImageFromDom,

    triggerClick,
    changeProperty,
    scrollHandler,
    enableScroll,
}