import { canvasHelper } from "./canvasHelper";

function getGatsbyImageData(node) {
    try {
        return node.relationships.field_image.localFile.childImageSharp.gatsbyImageData;
    } catch (error) {
        return null;   
    }
}
function getChildImgFluid(node) {
    try {
        return node.relationships.field_image.localFile.childImageSharp.fluid;
    } catch (error) {
        return null;   
    }
}
function getChildImgFixed(node) {
    try {
        return node.relationships.field_image.localFile.childImageSharp.fixed;
    } catch (error) {
        return null;
    }
}
function getChildImgSrc(node) {
    try {
        return node.relationships.field_image.localFile.childImageSharp.fluid.src;
    } catch (error) {
        return null;
    }
}
function getLinkUrl(field) {
    if(field && field.uri)
        return field.uri.slice(field.uri.indexOf('/'));
    return "/404";
}
function getUniqueKey(node) {
    return node.drupal_id ? node.drupal_id : (node.id ? node.id : (node.name ? node.name : node.title));
}

function getSafeLink(url) {
    return url ? url : "/404";
}
function getChildImgPublicUrl(node) {
    try {
        return node.relationships.field_image.localFile ? node.relationships.field_image.localFile.publicURL : null;
    } catch (error) {
        return null;   
    }
}
function parseLayoutString(str) {
    let parsed = {left: [], right: []}; // initialize a json for the whole layout
    let parsed_1 = str.split('-'); // get the left and right strings separately by splinting by '-'
    let parsed_1_left = parsed_1[0] ? parsed_1[0].trim().split(' ') : []; // get the left strings separately by splinting by ' '
    let parsed_1_right = parsed_1[1] ? parsed_1[1].trim().split(' ') : []; // get the right strings separately by splinting by ' '

    try {
        for( let i = 0 ; i < parseInt(parsed_1_left[2]) ; i ++)
            parsed.left.push(parsed_1_left[1]);
    } catch (error) {
        parsed.left = [];
    }
    try {
        for( let i = 0 ; i < parseInt(parsed_1_right[2]) ; i ++)
            parsed.right.push(parsed_1_right[1]);
    } catch (error) {
        parsed.right = [];
    }
    return parsed;
}

function parseLayout(layout) {
    // Extract Arts from the layout
  const artNames = layout["Art"] ? JSON.parse(layout["Art"]).clipart : [];
  const arts = artNames.map(artName => ({
      thumbnail: canvasHelper.getObjectFitInImgUrl("arts",artName),
      original: canvasHelper.getObjectImgUrl("arts",artName)
  }));

  // Extract Emojis from the layout
  const emojiNames = layout["Emojis"] ? JSON.parse(layout["Emojis"]).emoji : [];
  const emojis = emojiNames.map(emojiName => ({
      thumbnail: canvasHelper.getObjectFitInImgUrl("emojis", emojiName),
      original: canvasHelper.getObjectImgUrl("emojis", emojiName)
  }));
  // Extract Scenes from the layout
  const scenesNames = layout["Scenes"] ? JSON.parse(layout["Scenes"]).scene : [];
  const scenes = scenesNames.map(sceneName => ({
      thumbnail: canvasHelper.getObjectFitInImgUrl("scenes", sceneName),
      original: canvasHelper.getObjectImgUrl("scenes", sceneName)
  }));
  // Extract Stickers from the layout
  const stickerNames = layout["Stickers"] ? JSON.parse(layout["Stickers"]).stickers : [];
  const stickers = stickerNames.map(stickerName => ({
      thumbnail: canvasHelper.getObjectFitInImgUrl("stickers",stickerName),
      original: canvasHelper.getObjectImgUrl("stickers",stickerName)
  }));

  return { arts, emojis , scenes, stickers };
}

export const queryHelper = {
    getGatsbyImageData,
    getChildImgFluid,
    getChildImgFixed,
    getChildImgSrc,
    getLinkUrl,
    getUniqueKey,
    getSafeLink,
    getChildImgPublicUrl,
    parseLayoutString,
    parseLayout,
};