import { node } from 'prop-types';
import { queryHelper } from './queryHelper';
// import helper functions for gatsby
const slugHelper = require('./gatsbyHelper');

export const nodeHelper = {
    setUpTree,
    filterNodes,
    toCategories,           // convert query nodes to category object
    toCards,                // convert query nodes to card object
};

function setUpTree(node) {
    const edges = node.edges.map( edge => edge.node ); // create the flat array of edges
    const tree = nest(edges); // setup tree using edges and root(null)
    return tree;
};

// recurisve function to setup free from edges and parent-node
// filter the same level nodes that has the same parent-node and
// assign children with recursive call with the current node as a parent-node
function nest(edges, root = null) {
    const level = edges.filter(edge => {
                    const _parentMenuItem = edge['drupal_parent_menu_item'];
                    const parentMenuItem = _parentMenuItem ? _parentMenuItem.replace('menu_link_content:','') : null;
                    return parentMenuItem === root;
                })
                .map(edge => ({ ...edge, children: nest(edges, edge['drupal_id']) }));
    return level;
}
// filter nodes according to the imgfluid and title="Blank"    
function filterNodes(nodes) {
    const blockList = ["Blank"];
    return nodes.filter( node => {
        //const nodeImgFluid = queryHelper.getChildImgFluid(node); // removed as we have stopped using fluid
        const nodeImgGatsbyImage = queryHelper.getGatsbyImageData(node);
        return !blockList.includes(node.name) && nodeImgGatsbyImage;
    })
}
function toCategories(nodes) {
    return nodes.map(node => {
        return {
            key: queryHelper.getUniqueKey(node),
            name: node.name,
            slug: slugHelper.generateSlug(node.field_url),
            gatsbyImage: queryHelper.getGatsbyImageData(node),
            visibility: node.field_catalog_visibility,
        }
    })
}
function toCards(nodes) {
    return nodes.map(node => {
        return {
            key: queryHelper.getUniqueKey(node),
            slug: slugHelper.generateCardSlug(node.relationships.field_category.name, node.title),
            gatsbyImage: queryHelper.getGatsbyImageData(node),
        }
    })
}

