import React, { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { usersService } from '../services/usersService';
import UserContext from './UserContext';
import { Auth, Hub } from 'aws-amplify';


const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const createNotification = (type, title, msg = '') => {
    switch (type) {
      case 'info':
        NotificationManager.info(
          msg,
          title,
          parseInt(process.env.GATSBY_NOTIFICATION_LIFETIME) || 3000
        );
        break;
      case 'success':
        NotificationManager.success(
          msg,
          title,
          parseInt(process.env.GATSBY_NOTIFICATION_LIFETIME) || 3000
        );
        break;
      case 'warning':
        NotificationManager.warning(
          msg,
          title,
          parseInt(process.env.GATSBY_NOTIFICATION_LIFETIME) || 3000
        );
        break;
    }
  };

  const userContext = { user, setUser, createNotification, loading };
  useEffect(() => {
    const unsubscribe = Hub.listen(
      'auth',
      async ({ payload: { event, data } }) => {
        ////console.log(event, data, 'event, data ');
        switch (event) {
          case 'signIn':
            {
              const users = await usersService.getUser(data.username);
              if (users && users.length > 0) {
                setUser(users[0]);
              }
            }
            break;
          case 'signOut':
            setUser(null);
            break;
        }
      }
    );

    Auth.currentAuthenticatedUser()
      .then(async currentUser => {
        ////console.log('USER: ', currentUser, currentUser.storage.user);
        const users = await usersService.getUser(currentUser.username);
        setUser(users[0]);
        setLoading(false);
      })
      .catch(error => {
        ////console.log('Not signed in', error);
        setLoading(false);
      });

    return unsubscribe;
  }, []);

  return (
    <UserContext.Provider value={userContext}>{children}</UserContext.Provider>
  );
};

export default UserContextProvider;
