export const handleResponse = async (request, requestOptions) => {
    let resp = await fetch(request, requestOptions);
    switch(resp.status) {
        case 200:
            return await resp.json();
        case 404:
            return null;
        default:
            throw new Error("Network failed");
    }
}