exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-greeting-cards-brands-jsx": () => import("./../../../src/pages/greeting-cards/brands.jsx" /* webpackChunkName: "component---src-pages-greeting-cards-brands-jsx" */),
  "component---src-pages-greeting-cards-index-jsx": () => import("./../../../src/pages/greeting-cards/index.jsx" /* webpackChunkName: "component---src-pages-greeting-cards-index-jsx" */),
  "component---src-pages-greeting-cards-latest-jsx": () => import("./../../../src/pages/greeting-cards/latest.jsx" /* webpackChunkName: "component---src-pages-greeting-cards-latest-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-my-account-index-jsx": () => import("./../../../src/pages/my-account/index.jsx" /* webpackChunkName: "component---src-pages-my-account-index-jsx" */),
  "component---src-pages-my-account-order-jsx": () => import("./../../../src/pages/my-account/order.jsx" /* webpackChunkName: "component---src-pages-my-account-order-jsx" */),
  "component---src-pages-oauth-index-jsx": () => import("./../../../src/pages/oauth/index.jsx" /* webpackChunkName: "component---src-pages-oauth-index-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-templates-brand-js": () => import("./../../../src/templates/brand.js" /* webpackChunkName: "component---src-templates-brand-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-md-js": () => import("./../../../src/templates/md.js" /* webpackChunkName: "component---src-templates-md-js" */),
  "component---src-templates-workflow-js": () => import("./../../../src/templates/workflow.js" /* webpackChunkName: "component---src-templates-workflow-js" */)
}

